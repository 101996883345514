<script>
import Vue from 'vue';
import axios from 'axios';
import * as R from 'ramda';

const revisionStatus = revision => {
  if (!revision?.status) {
    return 'no_revisions_available';
  }
  if (![ 'published' ].includes(revision.status)) {
    return 'in_progress';
  }

  return 'ok';
};

export default {
  name: 'BulkUpdateConfigs',
  data: () => ({
    disable_messages: false,
    save_all_locked: false,
    clients: {
      ids: '',
      loading: false,
      list: [],
    },
    table_fields: [
      { key: 'id', label: '#' },
      { key: 'display_name', label: 'Name' },
      { key: 'actions', label: 'Actions' },
    ],
    update_note: 'Bulk Update - Enable referrals for dpt, bloom, and move',
    configs_to_update: {
      client: {
        configuration: {
          dpt: { toggles: { show_referrals: true } },
          bloom: { toggles: { show_referrals: true } },
          move: { toggles: { show_referrals: true } },
        },
      },
    },
  }),
  methods: {
    successMsg(msg) {
      if (this.disable_messages) {
        return;
      }
      Vue.prototype.$noty.success(msg);
    },
    errorMsg(msg) {
      if (this.disable_messages) {
        return;
      }
      Vue.prototype.$noty.error(msg);
    },

    isClientConfigsAlreadyCorrect(client) {
      if (client.configuration?.dpt?.toggles?.show_referrals
        !== this.configs_to_update.client.configuration.dpt.toggles.show_referrals) {
        return false;
      }
      if (client.configuration?.bloom?.toggles?.show_referrals
        !== this.configs_to_update.client.configuration.bloom.toggles.show_referrals) {
        return false;
      }
      if (client.configuration?.move?.toggles?.show_referrals
        !== this.configs_to_update.client.configuration.move.toggles.show_referrals) {
        return false;
      }

      return true;
    },
    fetchRevision(client) {
      client.status = 'loading';
      if (this.isClientConfigsAlreadyCorrect(client)) {
        client.status = 'already_correct';
        return;
      }

      axios.get('v1/revision-request', { params: { entityType: 'client', entityId: client.id } })
        .then(response => {
          if (response.data.items.length < 1) {
            client.status = 'no_revisions_available';
            return;
          }

          const lastRevision = response.data.items[0];
          client.status = revisionStatus(lastRevision);
          client.active_button = 'create';
          client.revision = { last_revision: lastRevision };
        })
        .catch(err => {
          console.error('error fetching revision requests', err);
          this.$noty.error('An error occurred while fetching client revisions.');
          client.status = 'error_loading';
        });
    },
    fetchClients() {
      this.clients.list = [];
      const clientIds = [];
      this.clients.ids.split(',').forEach(id => {
        const clientId = parseInt(id.trim(), 10);
        if (!clientId) {
          return;
        }
        clientIds.push(clientId);
      });
      if (clientIds.length < 1) {
        this.$noty.error('Please enter at least one client ID');
        return;
      }

      const queryString = clientIds.map(id => `id=${id}`).join('&');
      axios.get(`v2/clients?limit=50&${queryString}`)
        .then(response => {
          this.clients.list = response.data.items.map(item => ({
            id: item.id,
            active_button: '',
            status: '',
            revision: { last_revision: {} },
            display_name: item.display_name,
            configuration: item.configuration,
          }));

          this.clients.list.forEach(client => {
            this.fetchRevision(client);
          });
        })
        .catch(err => {
          console.error('error fetching clients', err);
          this.$noty.error('An error occurred while fetching clients.');
        });
    },

    saveCreate(client) {
      const originalData = client.revision.last_revision.requested_changes;
      const newData = R.mergeDeepRight(originalData, this.configs_to_update);

      return axios.post('/v1/revision-request', {
        entity_id: client.id,
        entity_type: 'client',
        requested_changes: JSON.stringify(newData),
      })
        .then(response => {
          this.successMsg('Successfully submitted the revision request');
          client.active_button = 'note';
          client.revision.current_id = response.data.id;
        })
        .catch(err => {
          this.errorMsg('Failed to submit revision');
          console.error('err', err);
        });
    },
    saveNote(client) {
      client.active_button = 'submit';
      return axios.patch(`/v1/revision-request/${client.revision.current_id}/notes`, { notes: this.update_note })
        .then(() => {
          this.successMsg('Successfully created note');
          client.active_button = 'submit';
        })
        .catch(err => {
          this.errorMsg('Failed to create note');
          console.error('err', err);
        });
    },
    saveRevisionState(client, state) {
      const data = {};
      const originalData = client.revision.last_revision.requested_changes;
      if (state === 'publish') {
        data.current_data = JSON.stringify(originalData);
      }

      return axios.patch(`/v1/revision-request/${client.revision.current_id}/${state}`, data)
        .then(() => {
          this.successMsg(`Successfully changed revision state to ${state}`);

          switch (state) {
            case 'submit':
              client.active_button = 'approve';
              break;
            case 'approve':
              client.active_button = 'publish';
              break;
            case 'publish':
              client.status = 'done';
              break;
            default:
              break;
          }
        })
        .catch(err => {
          this.errorMsg(`Failed to change revision state to ${state}`);
          console.error('err', err);
        });
    },
    async saveAll(client) {
      if (this.save_all_locked) {
        return;
      }
      this.save_all_locked = true;

      client.save_all_loading = true;
      try {
        this.disable_messages = true;
        await this.saveCreate(client);
        await this.saveNote(client);
        await this.saveRevisionState(client, 'submit');
        await this.saveRevisionState(client, 'approve');
        await this.saveRevisionState(client, 'publish');
        this.disable_messages = false;

        this.successMsg(`${client.display_name} updated successfully`);
      } catch (e) {
        console.error('error saving', e);
      }
      client.save_all_loading = false;
      this.save_all_locked = false;
    },
  },
};
</script>
<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h5>Bulk Update Configs</h5>
      </b-col>
    </b-row>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="6">
            <p><b>Note: </b>{{ update_note }}</p>
            <pre>{{ configs_to_update }}</pre>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Comma separated list of client IDs">
              <b-form-input v-model.trim="clients.ids" type="text" />
            </b-form-group>
            <b-button variant="primary" @click="fetchClients" class="float-right">Fetch clients</b-button>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col cols="12">
            <b-table :fields="table_fields" :items="clients.list" :busy="clients.loading"
                     outlined striped small :show-empty="true" empty-text="No clients to show">
              <template #cell(id)="data">
                <router-link :to="{ name: 'EditClient', params: { clientID: data.item.id } }" target="_blank">
                  {{ data.item.id }}
                </router-link>
              </template>
              <template #cell(actions)="data">
                <template v-if="data.item.status === 'loading'">
                  <b-spinner class="align-middle" small />
                </template>
                <template v-if="data.item.status === 'done'">
                  <b-badge variant="success">Completed. Great success.</b-badge>
                </template>
                <template v-if="data.item.status === 'already_correct'">
                  <b-badge variant="success">Already correct</b-badge>
                </template>
                <template v-if="data.item.status === 'no_revisions_available'">
                  <b-badge variant="danger">No revisions available</b-badge>
                </template>
                <template v-if="data.item.status === 'error_loading'">
                  <b-badge variant="danger" @click="fetchRevision(data.item)">Error loading. Retry</b-badge>
                </template>
                <template v-if="data.item.status === 'in_progress'">
                  <b-badge variant="danger">There's already an ongoing revision.</b-badge>
                </template>
                <template v-if="data.item.status === 'ok'">
                  <b-badge role="button" :disabled="data.item.active_button !== 'create'"
                           :variant="data.item.active_button === 'create' ? 'primary': 'secondary'"
                           class="mr-3"
                           @click="saveCreate(data.item)">
                    create
                  </b-badge>
                  <b-badge role="button" :disabled="data.item.active_button !== 'note'"
                           :variant="data.item.active_button === 'note' ? 'primary': 'secondary'"
                           class="mr-3"
                           @click="saveNote(data.item)">
                    add note
                  </b-badge>
                  <b-badge role="button" :disabled="data.item.active_button !== 'submit'"
                           :variant="data.item.active_button === 'submit' ? 'primary': 'secondary'"
                           class="mr-3"
                           @click="saveRevisionState(data.item, 'submit')">
                    submit
                  </b-badge>
                  <b-badge role="button" :disabled="data.item.active_button !== 'approve'"
                           :variant="data.item.active_button === 'approve' ? 'primary': 'secondary'"
                           class="mr-3"
                           @click="saveRevisionState( data.item, 'approve')">
                    approve
                  </b-badge>
                  <b-badge role="button" :disabled="data.item.active_button !== 'publish'"
                           :variant="data.item.active_button === 'publish' ? 'primary': 'secondary'"
                           class="mr-3"
                           @click="saveRevisionState(data.item, 'publish')">
                    publish
                  </b-badge>

                  <b-badge v-if="!data.item.save_all_loading"
                           role="button" :disabled="data.item.active_button !== 'create'"
                           :variant="data.item.active_button === 'create' ? 'warning': 'secondary'"
                           class="mr-3 float-right"
                           :style="save_all_locked ? 'cursor: not-allowed;' : ''"
                           @click="saveAll(data.item)">
                    save all
                  </b-badge>
                  <b-spinner v-else class="mr-4 float-right" small />

                </template>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </b-container>
</template>
